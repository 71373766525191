import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';

const { Clipboard } = Plugins;
const KEY_LAST_EZCLO_QUOTE_ID = 'KEY_LAST_EZCLO_QUOTE_ID';

@Injectable({
  providedIn: 'root'
})
export class ClipboardService {

  constructor() { }

  async copyText(text: string): Promise<void> {
    Clipboard.write({
      string: text
    });
    localStorage.setItem(KEY_LAST_EZCLO_QUOTE_ID, text);
  }

  async readClipBoardText(): Promise<string> {
    const result = await Clipboard.read();
    environment.debug && console.log('Got', result.type, 'from clipboard:', result.value);
    return result.value;
  }

  getStoredEzcloQuoteId(): string {
    return localStorage.getItem(KEY_LAST_EZCLO_QUOTE_ID) || undefined;
  }

  async getLastEzcloQuoteId(): Promise<string> {
    const quoteId = this.getStoredEzcloQuoteId();
    if(!quoteId) return Promise.reject('No hemos encontrado cotizaciones recientes');
    this.copyText(quoteId);
    return Promise.resolve(quoteId);
  }
}
