import { ICompanyData } from './../../models/company.model';
import { environment } from '../../../environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { skip, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';

const COMPANY_DOCREF = `company/${environment.companyDocId}`;
const PRODUCTS_ENVIRONMENT = environment.production ? 'products' : 'devproducts';
const PRODUCTS_ENV = environment.production ? 'prod' : 'dev';
const PROD_AFSTORE = `${COMPANY_DOCREF}/products/${PRODUCTS_ENV}`;

@Injectable({
  providedIn: 'root'
})
export class CompanyInfoService {
  private subjectEmiter = new BehaviorSubject<ICompanyData>(undefined);
  private companyData: ICompanyData = undefined;
  private data$: Observable<ICompanyData> = undefined;
  private subjectProdEmiter = new BehaviorSubject<any>(undefined);
  private productsData: any = undefined;
  private products$: Observable<any> = undefined;

  constructor(
    private afs: AngularFirestore
  ) {
    this.data$ = this.subjectEmiter.asObservable().pipe((skip(1)));
    this.updateSharedData().subscribe(() => environment.debug && console.log('LND - Caching company info!!'));
    this.products$ = this.subjectProdEmiter.asObservable().pipe(skip(1));
    this.updateSharedProdData().subscribe(() =>
     environment.debug && console.log('MNG-Caching Prod company info!!')
    );
  }

  getSharedCompanyInfo(): Observable<ICompanyData> {
    if (this.data$) {
      return this.data$;
    }
    this.afs.doc<ICompanyData>(COMPANY_DOCREF).valueChanges().pipe(tap(data => {
      this.companyData = { ...data };
      this.companyData.products = { ...data[PRODUCTS_ENVIRONMENT] };
    }));
    return this.data$;
  }

  updateSharedData(): Observable<ICompanyData> {
    return this.afs.doc<ICompanyData>(COMPANY_DOCREF).valueChanges().pipe(tap(data => {
      this.companyData = { ...data };
      environment.debug && console.log('LND-Caché loaded');
      this.companyData.products = { ...data[PRODUCTS_ENVIRONMENT] };
      this.subjectEmiter.next(this.companyData);
    }));
  }

  updateSharedProdData(): Observable<any> {
    return this.afs
      .doc(PROD_AFSTORE)
      .valueChanges()
      .pipe(
        tap((data) => {
          this.productsData = { ...data };
          environment.debug && console.log('MNG-Compay Prod Cached', data);
          this.subjectProdEmiter.next(this.productsData);
        })
      );
  }

  getCachedStream(): Observable<ICompanyData> {
    if (this.companyData) {
      return of(this.companyData);
    } else {
      return this.getSharedCompanyInfo();
    }
  }

  getCachedProdStream(): Observable<any> {
    if (this.productsData) {
      return of(this.productsData);
    } else {
      return this.getSharedProducts();
    }
  }

  getCompanyDataSnapshot(): ICompanyData {
    return this.companyData;
  }

  private getSharedProducts(): Observable<any> {
    if (this.products$) {
      return this.products$;
    }
    this.afs
      .doc<any>(PROD_AFSTORE)
      .valueChanges()
      .pipe(
        tap((data) => {
          this.productsData = { ...data };
        })
      );
    return this.products$;
  }
}
