import { ILocation } from './../../models/location.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, of } from 'rxjs';

const LOCATION_PROXY_API = `${environment.apis.integrations}/v1/proxy/locations`;
@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private http: HttpClient) { }

  getLocation(params: {query: string, countryCode: string}): Observable<ILocation[]> {
    return this.http.get<ILocation[]>(LOCATION_PROXY_API, {params});
  }
}
