import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ICountry } from '../../models/country.model';
import { tap } from 'rxjs/operators';

const COUNTRIES_API = `${environment.apis.integrations}/v1/countries`;
@Injectable({
  providedIn: 'root'
})
export class CountriesService {
  countries: ICountry[];

  constructor(private http: HttpClient) {}

  getCountries(): Observable<ICountry[]> {
    if(this.countries) {
      return of(this.countries);
    }
    return this.http
      .get<ICountry[]>(COUNTRIES_API)
      .pipe(tap((i) => this.countries = i));
  }
}
