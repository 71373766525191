import { ICountry, defaultCountryCompany } from './../../../../models/country.model';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { debounceTime, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap/typeahead/typeahead';

@Component({
  selector: 'app-country-search',
  templateUrl: './country-search.component.html',
  styleUrls: ['./country-search.component.scss']
})
export class CountrySearchComponent implements OnInit, OnChanges {
  @Input() countries: ICountry[];
  @Output() selectedCountry = new EventEmitter<ICountry>();
  @Input() isDisabled = false;
  @Input() defaultValue: ICountry;
  @Input() showLabel = false;
  @Input() placeAddHolderLabel = '';
  @Input() import = 'local';
  public country: ICountry;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    const isDisabledNew = changes.isDisabled;
    const defaultValueNew = changes.defaultValue;
    const placeAddHolderLabel = changes.placeAddHolderLabel;
    if (isDisabledNew) {
      this.isDisabled = isDisabledNew.currentValue;
    }
    if (defaultValueNew) {
      this.defaultValue = defaultValueNew.currentValue;
    }
    if (placeAddHolderLabel) {
      this.placeAddHolderLabel = placeAddHolderLabel.currentValue;
    }
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      map(term => (term === '' || term.length < 3) ? []
        : this.countries
          .filter(i => this.import === 'local' ? this.isMexico(i) : !this.isMexico(i))
          .filter(v => v.translationEs.toLowerCase().indexOf(term.toLowerCase()) > -1
            || v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))

    );

  isMexico(item: ICountry): boolean {
    return item.alpha2Code.toLowerCase() === defaultCountryCompany.alpha2Code.toLowerCase();
  }
  ngOnInit(): void {
    this.loadData();
  }

  private loadData(): void {
    if (this.defaultValue) {
      this.country = this.defaultValue;
    }
  }

  public formatter(x: ICountry): string {
    return x.translationEs || x.name;
  }

  public selected(value: NgbTypeaheadSelectItemEvent<ICountry>): void {
    this.selectedCountry.emit(value.item);
  }

}
