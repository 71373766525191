<ng-template #rt let-r="result" let-t="term">
  <img [src]="r.flag | flagUrl" class="mr-1" width="25">
  <ngb-highlight [result]="r.translationEs || r.name" [term]="t"></ngb-highlight>
</ng-template>

<label for="typeahead-country" *ngIf="showLabel">{{showLabel}}:
  <img *ngIf="!!country && !!country.flagRounded" [src]="country.flagRounded" class="mr-1" width="20"></label>
<input type="text" class="form-control" [(ngModel)]="country" [ngbTypeahead]="search" [disabled]="isDisabled"
  [autofocus]="false" [showHint]="true" placeholder="País {{placeAddHolderLabel}}" autocomplete="molayadev"
  [resultTemplate]="rt" [editable]="true" [inputFormatter]="formatter" (selectItem)="selected($event)" />
