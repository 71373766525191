import { ILocation } from './../../../../models/location.model';
import { LocationService } from './../../../services/location.service';
import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Observable, of } from 'rxjs';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, map, filter } from 'rxjs/operators';

const DEFAULT_LOCATION: ILocation = {
  postal_code: '',
  state_code: '',
  value: '',
  id: '',
  neighborhood: '',
  city: '',
  district: ''
};
@Component({
  selector: 'app-location-search',
  templateUrl: './location-search.component.html',
  styleUrls: ['./location-search.component.scss']
})
export class LocationSearchComponent implements OnInit, OnChanges {
  @Output() selectedLocation = new EventEmitter<ILocation>();
  @Input() isDisabled = false;
  @Input() showLabel = false;
  @Input() country;
  @Input() defaultValueLocation: ILocation;
  @Input() placeAddHolderLabel = '';
  searching = false;
  searchFailed = false;
  public location: ILocation;

  constructor(private locationService: LocationService) { }

  ngOnChanges(changes: SimpleChanges): void {
    const isDisabledNew = changes.isDisabled;
    const isCountryNew = changes.country;
    const isLocationNew = changes.defaultValueLocation;
    const placeAddHolderLabel = changes.placeAddHolderLabel;
    if (isDisabledNew) {
      this.isDisabled = isDisabledNew.currentValue;
    }
    if (isCountryNew) {
      this.country = isCountryNew.currentValue;
      this.defaultValueLocation = { ...DEFAULT_LOCATION };
      this.location = { ...DEFAULT_LOCATION };
    }
    if (isLocationNew) {
      this.defaultValueLocation = isLocationNew.currentValue;
      this.loadData();
    }
    if (placeAddHolderLabel) {
      this.placeAddHolderLabel = placeAddHolderLabel.currentValue;
    }
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      filter((term) => term.length > 2),
      switchMap(term =>
        this.locationService.getLocation({ query: term, countryCode: this.country }).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    )

  ngOnInit(): void {
    this.loadData();
  }

  private loadData(): void {
    if (this.defaultValueLocation) {
      this.location = this.defaultValueLocation;
    } else {
      this.location = { ...DEFAULT_LOCATION };
    }
  }

  public formatter(x: { value: string }): string {
    return x.value;
  }

  public selected(value: NgbTypeaheadSelectItemEvent<ILocation>): void {
    this.selectedLocation.emit(value.item);
  }

}
