
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'landing',
    pathMatch: 'full'
  },
  { path: 'coming-soon', loadChildren: () => import('./pages/coming-soon-page/coming-soon-page.module').then(m => m.ComingSoonPageModule)},
  { path: 'landing', loadChildren: () => import('./pages/landing-page/landing-page.module').then(m => m.LandingPageModule)},
  { path: 'contact-us', loadChildren: () => import('./pages/contact-page/contact-page.module').then(m => m.ContactPageModule)},
  { path: 'faqs', loadChildren: () => import('./pages/faqs-page/faqs-page.module').then(m => m.FaqsPageModule)},
  { path: 'quote', loadChildren: () => import('./pages/quote-page/quote-page.module').then(m => m.QuotePageModule)},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
