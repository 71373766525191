import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import H from '@here/maps-api-for-javascript';
import { filter, map, tap } from 'rxjs/operators';


const HERE_API_KEY = environment.hereApiKey;
const AUTOCOMPLE_URL = 'https://autocomplete.search.hereapi.com/v1/autocomplete';
const GEOCODE_URL = 'https://geocode.search.hereapi.com/v1/geocode';
const QUERY_AREA = `lang=es&apiKey=${HERE_API_KEY}&limit=11`;

@Injectable({
  providedIn: 'root'
})
export class HereService {
  platform: H.service.Platform;
  searchService: H.service.SearchService;
  constructor(private http: HttpClient) {
    this.platform = new H.service.Platform({
      apikey: HERE_API_KEY
    });
    this.searchService = this.platform.getSearchService();
  }

  search(query: string, countryCode?: string) {
    const querySearch = encodeURI(query);
    const specificCountry = countryCode ? `&in=countryCode%3A${countryCode}` : '';
    const url = `${AUTOCOMPLE_URL}?${QUERY_AREA}&q=${querySearch}${specificCountry}`;
    return this.http.get(url).pipe(
      map((i: any) => i.items
      .filter(i => (countryCode === 'MEX' && !!i.address?.postalCode) || (countryCode !== 'MEX' && !!i.address?.street))
      .map((i: any) => {
          i.selected = false;
          const title = i.title;
          const address = i.address;
          const street = i.address.street;
          const postalCode = i.address?.postalCode;
          const hasPostalCode = title.includes(postalCode);
          i.titleFormatted = hasPostalCode ? title :
            `${address.countryName}, ${address?.postalCode || '--'}, ${address.city}, ${street || ''}`;
          i.value = i.titleFormatted;
          i.postal_code = postalCode;
          i.city = i.address.city || i.address.state || '';
          return i;
        })
      ),
      tap(i => environment.debug && console.log('SEARCHED HERE DATA', i))
    );
  }

  searchGeocode(query: string, countryCode?: string, result?: (any) => void) {
    const specificCountry = countryCode ? `&in=countryCode%3A${countryCode}` : '';
    const querySearch = encodeURI(query);
    const url = `${GEOCODE_URL}?${QUERY_AREA}&q=${querySearch}${specificCountry}`;
    return this.http.get(url).pipe(
      map((i: any) => i.items
      .filter(i => (countryCode === 'MEX' && !!i.address?.postalCode) || (countryCode !== 'MEX' && !!i.address?.street))
      .map((i: any) => {
        i.selected = false;
        const title = i.title;
        const address = i.address;
        const street = i.address.street;
        const postalCode = i.address?.postalCode;
        const hasPostalCode = title.includes(postalCode);
        i.titleFormatted = hasPostalCode ? title :
          `${address.countryName}, ${address?.postalCode || '--'}, ${address.city}, ${street || ''}`;
        i.value = i.titleFormatted;
        i.postal_code = postalCode;
        i.city = i.address.city || i.address.state || '';
        return i;
      })),
      filter(i => !!i.address.postalCode),
      tap(i => environment.debug && console.log('SEARCHED HERE GEO DATA', i))
    );
  }
}
