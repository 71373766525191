// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { name, version } from '../../../../package.json';

export const environment = {
  production: false,
  name,
  newVersion: '0.7.0',
  version: `DEV-v${version}.4`,
  visibleCharacters: 7,
  packageConfigurations: {
    extendedZoneCharge: {
      dhl: 190,
      estafeta: 110,
      fedex: 170,
      redpack: 150,
      paqueteexpress: 160
    },
    currency: {
      code: 'MXN',
      symbol: '$'
    }
  },
  apis: {
    extendedZoneNonOfficial: 'https://extendedzonemexico-proxytest.herokuapp.com/extendedZone',
    test: 'https://extendedzonemexico-proxytest.herokuapp.com/location',
    integrations: 'https://us-central1-ezclo-functions.cloudfunctions.net/integrations/api'
  },
  mailerToken: 'vOwTbtw7UtRz8rsCsjDo',
  firebaseConfig: {
    apiKey: 'AIzaSyAHbWCkXHpvY13R7gVlXD0kmgesCV-LGoc',
    authDomain: 'ezclo-core.firebaseapp.com',
    databaseURL: 'https://ezclo-core.firebaseio.com',
    projectId: 'ezclo-core',
    storageBucket: 'ezclo-core.appspot.com',
    messagingSenderId: '612664166402',
    appId: '1:612664166402:web:33b7ccf0fa7a0855d73f1b',
    measurementId: 'G-VKJ8ZR2J4F'
  },
  hereApiKey: 'FANGgX1zCsmlD9iCm9l53KEqLSHnOZ3Vnl-Q5wuzeUk',
  companyDocId: 'pGKKZstzoJyAZC5kxvO5',
  contact: {
    name: 'Ezclo',
    phone: '3325652123',
    email: 'enviojabino@gmail.com',
    title: 'Guías electrónicas y mensajería!',
    message: 'Contáctanos',
    phoneLink: '3325652123',
    whatsapp: '3325652123',
    whatsappLink: '523325652123'
  },
  debug: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
