export interface ICountry {
  name: string;
  alpha2Code: string;
  alpha3Code?: string;
  callingCode?: string;
  capital?: string;
  region?: string;
  subregion?: string;
  population?: string;
  demonym?: string;
  nativeName?: string;
  numericCode?: string;
  currencyCode?: string;
  currencyName?: string;
  currencySymbol?: string;
  translationEs: string;
  flag: string;
  flagRounded: string;
  zone: string;
}

export const defaultCountryCompany = {
  name: 'Mexico',
  alpha2Code: 'MX',
  alpha3Code: 'MEX',
  callingCode: '52',
  numericCode: '484',
  currencyCode: 'MXN',
  currencyName: 'Mexican peso',
  currencySymbol: '$',
  translationEs: 'México',
  flag: 'https://restcountries.com/data/mex.svg',
  flagRounded: '',
  zone: '0'
};
