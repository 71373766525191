import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'flagUrl'
})
export class FlagUrlPipe implements PipeTransform {

  constructor() {
  }

  transform(value: string, ...args: string[]): string {
    return value
    .replace('restcountries.eu', 'restcountries.com')
    .replace('https://restcountries.com/data/', 'https://raw.githubusercontent.com/molayadev/country-data-interface/master/web/data/flags/');
  }
}
