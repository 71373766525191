import { Component, EventEmitter, Input, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-flip-message',
  templateUrl: './flip-message.component.html',
  styleUrls: ['./flip-message.component.scss']
})
export class FlipMessageComponent implements OnInit {

  @Input() isOnline = true;
  @Output() isOnlineChange = new EventEmitter<boolean>(true);
  constructor(private renderer: Renderer2) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(change: SimpleChanges) {
    const isOnlineChange = change.isOnline;
    if (!!isOnlineChange) {
      this.isOnline = isOnlineChange.currentValue;
    }
  }

  change() {
    this.isOnlineChange.emit(this.isOnline);
  }

}
