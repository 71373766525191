import { CompanyInfoService } from './../../services/company-info.service';
import { Component, OnInit } from '@angular/core';
import { IContact } from '../../../models/contact.model';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  contact: IContact;
  constructor(private companyInfoService: CompanyInfoService) { }

  ngOnInit(): void {
    this.companyInfoService.getCachedStream().subscribe(
      data => {
        this.contact = {...data};
        // console.log('Email: ', this.contact.email);
      }
    );
  }

}
