import { defaultCountryCompany } from './../../../models/country.model';
import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges, enableProdMode } from '@angular/core';
import { ILocation } from '../../../models/location.model';
import { ICountry } from '../../../models/country.model';

const DEFAULT_LOCATION: ILocation = {
  postal_code: '',
  state_code: '',
  value: '',
  id: '',
  neighborhood: '',
  city: '',
  district: ''
};
@Component({
  selector: 'app-location-container',
  templateUrl: './location-container.component.html',
  styleUrls: ['./location-container.component.scss']
})
export class LocationContainerComponent implements OnInit, OnChanges {
  @Input() textLabel = 'Lugar';
  @Input() placeAddHolderLabel = '';
  @Input() countryValue: ICountry;
  @Input() defaultValue: ILocation;
  @Input() useHere = false;
  @Input() import = 'local';
  @Input() enableSourceAndDestiny = false;
  @Output() selectedLocation = new EventEmitter<ILocation>();
  @Output() selectedCountry = new EventEmitter<ICountry>();
  country: string;
  @Input() isDisabled: boolean;
  @Input() isDisabledCountry: boolean;
  shouldShow = false;
  countryCompany = defaultCountryCompany;

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    const enableSourceAndDestiny = changes.enableSourceAndDestiny;
    const placeAddHolderLabel = changes.placeAddHolderLabel;
    const importNew = changes.import;
    if (importNew) {
      this.import = importNew.currentValue;
    }
    if (enableSourceAndDestiny) {
      this.enableSourceAndDestiny = enableSourceAndDestiny.currentValue;
    }
    if (changes.countryValue) {
      this.countryValue = changes.countryValue.currentValue;
      this.country = this.useHere ? this.countryValue?.alpha3Code : this.countryValue?.alpha2Code;
    }
    if (changes.defaultValue) {
      this.defaultValue = changes.defaultValue.currentValue;
    }
    if (placeAddHolderLabel) {
      this.placeAddHolderLabel = placeAddHolderLabel.currentValue;
    }
  }

  ngOnInit(): void {
    this.loadData();
  }

  private loadData(): void {
    if (this.countryValue) {
      this.country = this.useHere ? this.countryValue.alpha3Code : this.countryValue.alpha2Code;
    }
  }

  selectedCountryOption(event: ICountry): void {
    this.selectedCountry.emit(event);
    this.country = !!event ? this.useHere ? event.alpha3Code : event.alpha2Code : undefined;
    this.defaultValue = { ...DEFAULT_LOCATION };
  }

  selectedLocationOption(event: ILocation): void {
    this.selectedLocation.emit(event);
  }

  showOriginOrDestiny(isOrigin: boolean): boolean {
    if (!this.enableSourceAndDestiny) {
      return false;
    }
    const isImport = this.import === 'import';
    return (isOrigin && isImport) || (!isOrigin && !isImport);
  }
}
