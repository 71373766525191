<div class="flex-container-global">

  <div class="flex-container flex-item" *ngIf="enableSourceAndDestiny && import !== 'import'">
    <button type="button" class="btn-search dark" *ngIf="!selectedCountryFlag"> Origen <img [src]="countryCompany.flag | flagUrl" width="35"></button>
  </div>
  <div class="flex-item">
    <app-country-container [defaultValueCountry]="countryValue"
      [textLabel]="enableSourceAndDestiny? import === 'import'? 'Origen': 'Destino' : textLabel"
      [isDisabled]="isDisabledCountry || import === 'local'" (selectedCountry)="selectedCountryOption($event)"
      (shouldShowLocation)="shouldShow = $event" [import]="import" [placeAddHolderLabel]="placeAddHolderLabel">
    </app-country-container>
  </div>
  <div class="flex-container flex-item" *ngIf="enableSourceAndDestiny && import === 'import'">
    <button type="button" class="btn-search dark" *ngIf="!selectedCountryFlag"> Destino <img [src]="countryCompany.flag | flagUrl" width="35"></button>
  </div>
</div>
<div *ngIf="!!country && !useHere">
  <app-location-search *ngIf="!!country" [country]="country" [defaultValueLocation]="defaultValue"
    [isDisabled]="isDisabled" (selectedLocation)="selectedLocationOption($event)"
    [placeAddHolderLabel]="placeAddHolderLabel"></app-location-search>
</div>
<div *ngIf="!!country && useHere">
  <app-location-here-search *ngIf="!!country" [countryCode]="country" [defaultValueLocation]="defaultValue"
    [isDisabled]="isDisabled" (selectedLocation)="selectedLocationOption($event)"
    [placeAddHolderLabel]="placeAddHolderLabel"></app-location-here-search>
</div>
