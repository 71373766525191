import { environment } from './../../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { CompanyInfoService } from '../../services/company-info.service';
import { ICompanyParcel } from '../../../models/company.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  version = environment.version;
  phrase: string;
  parcels: ICompanyParcel[] = [];
  constructor(private companyInfoService: CompanyInfoService) { }

  ngOnInit(): void {
    this.companyInfoService.getCachedStream().subscribe(data => {
      this.phrase = data.phrase;
      this.parcels = data.parcels;
    });
  }


}
