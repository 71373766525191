import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  private debugEnabled = environment.debug;
  constructor() { }

  debug(message: any, param?: any): void {
    const format = `**> DEBUG -> ${message} `;
    if (!this.debugEnabled) { return; }
    if (!!param) {
      console.log(format, param);
    } else {
      console.log(format, param);
    }
  }

  info(message: any, param?: any): void {
    const format = `**> INFO -> ${message} `;
    if (!!param) {
      console.log(format, param);
    } else {
      console.log(format, param);
    }
  }

  error(message: any, param?: any): void {
    const format = `**> ERROR -> ${message} `;
    if (!!param) {
      console.error(format, param);
    } else {
      console.error(format, param);
    }
  }
}
