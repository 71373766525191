import { CountriesService } from './../../services/countries.service';
import { Component, OnInit, forwardRef, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { ICountry } from '../../../models/country.model';

@Component({
  selector: 'app-country-container',
  templateUrl: './country-container.component.html',
  styleUrls: ['./country-container.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CountryContainerComponent),
      multi: true
    }
  ]
})
export class CountryContainerComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input() textLabel = 'País';
  @Input() defaultValueCountry: ICountry;
  @Input() isDisabled: boolean;
  @Input() placeAddHolderLabel = '';
  @Output() selectedCountry = new EventEmitter<ICountry>();
  @Output() shouldShowLocation = new EventEmitter<boolean>();
  @Input() import = 'local';
  public countries$: Observable<ICountry[]>;
  selectedCountryFlag: string;
  value: string;
  showCountry = true;

  onChange = (_: any) => { };
  onTouch = () => { };

  constructor(private countriesService: CountriesService) { }

  ngOnChanges(changes: SimpleChanges): void {
    const isNewCountry = changes.defaultValueCountry;
    const placeAddHolderLabel = changes.placeAddHolderLabel;
    const importNew = changes.import;
    if (importNew) {
      this.import = importNew.currentValue;
    }
    if (isNewCountry) {
      this.defaultValueCountry = isNewCountry.currentValue;
      this.loadData();
    }
    if (placeAddHolderLabel) {
      this.placeAddHolderLabel = placeAddHolderLabel.currentValue;
    }
  }

  ngOnInit(): void {
    this.countries$ = this.countriesService.getCountries();
    this.loadData();
  }

  private loadData(): void {
    if (!this.defaultValueCountry) {
      this.selectedCountryFlag = undefined;
      this.value = undefined;
      this.showCountry = true;
      return;
    }
    this.selectedCountryFlag = this.defaultValueCountry.flag || this.defaultValueCountry.flagRounded;
    this.value = this.defaultValueCountry.translationEs || this.defaultValueCountry.name;
    this.showCountry = false;
  }

  showCountrySearch(): void {
    if (this.isDisabled) { return; }
    this.selectedCountry.emit(undefined);
    this.selectedCountryFlag = undefined;
    this.showCountry = true;
  }

  selected(value: ICountry): void {
    this.showCountry = false;
    this.selectedCountryFlag = value.flag || value.flagRounded;
    this.onChange(value);
    this.onTouch();
    this.selectedCountry.emit(value);
  }

  writeValue(value: string): void {
    if (value) {
      this.value = value;
    } else {
      this.value = '';
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
