<ng-template #rt let-r="result" let-t="term">
  <ngb-highlight [result]="r.titleFormatted" [term]="t"></ngb-highlight>
</ng-template>

<label for="typeahead-location" *ngIf="!!showLabel">{{showLabel}}: </label>
<input type="text" class="form-control" [(ngModel)]="location" [ngbTypeahead]="search"
  [disabled]="isDisabled" [autofocus] ="true" [showHint]="false" [class.is-invalid]="searchFailed"
  autocomplete="molayadev"
  [editable]="false" [inputFormatter]="formatter" (selectItem)="selected($event)" [placeholder]="'Código Postal/Ciudad '+ placeAddHolderLabel"
  [resultTemplate]="rt"/>
<small *ngIf="searching" class="form-text text-muted">Buscando...</small>
<div class="invalid-feedback" *ngIf="searchFailed">Lo sentimos, no se pudo cargar el servicio por problemas de conexión a internet.</div>
