import { SharedModule } from './shared/shared.module';
import { environment } from './../environments/environment.prod';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// 1. Import the libs you need
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import {
  AngularFireAnalyticsModule,
  CONFIG,
  APP_NAME,
  APP_VERSION,
  DEBUG_MODE,
  ScreenTrackingService,
  COLLECTION_ENABLED
} from '@angular/fire/analytics';

import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxSpinnerModule } from 'ngx-spinner';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { IonicModule } from '@ionic/angular';

const FIREBASE_IMPORTS = [
  AngularFireModule.initializeApp(environment.firebaseConfig),
  AngularFirestoreModule, // firestore
  AngularFireAuthModule, // auth
  AngularFireStorageModule, // storage
  AngularFireAnalyticsModule // analytics
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    NgxSpinnerModule,
    SweetAlert2Module.forRoot(),
    ...FIREBASE_IMPORTS,
    ServiceWorkerModule.register('allsw-worker.js', {
      enabled: environment.production
    }),
    BrowserAnimationsModule,
    IonicModule.forRoot()
  ],
  providers: [
    ScreenTrackingService,
    {
      provide: CONFIG,
      useValue: {
        allow_ad_personalization_signals: false,
        anonymize_ip: false
      }
    },
    { provide: APP_NAME, useValue: environment.name },
    { provide: COLLECTION_ENABLED, useValue: true },
    { provide: APP_VERSION, useValue: environment.version },
    { provide: DEBUG_MODE, useValue: environment.debug }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
