import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { NavItem } from '../model/header.model';

@Component({
  selector: 'app-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss']
})
export class NavItemComponent implements OnInit {
  @Input() nav: NavItem;
  @Input() selected = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  onClick(): void {
    this.selected.emit(true);
  }

}
