
import { HereService } from './../../../services/here.service';
import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Observable, of } from 'rxjs';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, filter } from 'rxjs/operators';

const DEFAULT_LOCATION: any = {
  titleFormatted: '',
};
@Component({
  selector: 'app-location-here-search',
  templateUrl: './location-here-search.component.html',
  styleUrls: ['./location-here-search.component.scss']
})
export class LocationHereSearchComponent implements OnInit, OnChanges {
  @Output() selectedLocation = new EventEmitter<any>();
  @Input() isDisabled = false;
  @Input() showLabel = false;
  @Input() countryCode;
  @Input() defaultValueLocation: any;
  @Input() placeAddHolderLabel = '';
  searching = false;
  searchFailed = false;
  public location: any;

  constructor(private here: HereService) { }

  ngOnChanges(changes: SimpleChanges): void {
    const isDisabledNew = changes.isDisabled;
    const isCountryNew = changes.countryCode;
    const isLocationNew = changes.defaultValueLocation;
    if (isDisabledNew) {
      this.isDisabled = isDisabledNew.currentValue;
    }
    if (isCountryNew) {
      this.countryCode = isCountryNew.currentValue;
      this.defaultValueLocation = {...DEFAULT_LOCATION};
      this.location = {...DEFAULT_LOCATION};
    }
    if (isLocationNew) {
      this.defaultValueLocation = isLocationNew.currentValue;
      this.loadData();
    }
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      filter( (term) => term.length > 2),
      switchMap(term =>
        this.here.search(term, this.countryCode).pipe(
          tap(() => this.searchFailed = false),
          catchError((err) => {
            console.error('Searching Error', err);
            this.searchFailed = true;
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    )

  ngOnInit(): void {
    this.loadData();
  }

  private loadData(): void {
    if (this.defaultValueLocation) {
      this.location = this.defaultValueLocation;
    } else {
      this.location = {...DEFAULT_LOCATION};
    }
  }

  public formatter(x: {titleFormatted: string}): string {
    return x.titleFormatted;
  }

  public selected(value: NgbTypeaheadSelectItemEvent<any>): void {
    this.location = value.item;
    this.selectedLocation.emit(value.item);
  }

}
