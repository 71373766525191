<!-- Start Sidebar Modal -->
<div class="sidebar-modal">
  <div class="modal right fade" id="myModal2" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i
                class="icofont-close"></i></span></button>

          <h2 class="modal-title" id="myModalLabel2">
            <app-menu-logo></app-menu-logo></h2>
        </div>

        <div class="modal-body">
          <div class="sidebar-modal-widget" *ngIf="contact">
            <h3 class="title">Información de Contacto</h3>

            <ul class="contact-info">
              <li>
                <a href="mailto:{{contact.email}}?subject='Contacto'">
                  <i class="icofont-email"></i>
                  Correo
                  <span>{{contact.email}}</span>
                </a>
              </li>
              <li>
                <a href="tel:{{contact.phoneLink}}">
                  <i class="icofont-phone"></i>
                  Teléfono
                  <span>+{{contact.phone}}</span>
                </a>
              </li>
              <li>
                <a href="{{contact.whatsappLink}}">
                  <i class="icofont-whatsapp"></i>
                  Whatsapp
                  <span>+{{contact.whatsapp}}</span>
                </a>
              </li>
            </ul>
          </div>

          <div class="sidebar-modal-widget">
            <h3 class="title">Encuentranos:</h3>

            <ul class="social-list">
              <li><a href="https://www.facebook.com/EzcloGuias" target="_blank"><i class="icofont-facebook"></i></a>
              </li>
              <li><a href="https://www.instagram.com/ezcloguias?r=nametag" target="_blank"><i
                    class="icofont-instagram"></i></a></li>
            </ul>
          </div>
        </div>
      </div><!-- modal-content -->
    </div><!-- modal-dialog -->
  </div><!-- modal -->
</div>
<!-- End Sidebar Modal -->
