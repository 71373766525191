import { FlagUrlPipe } from './pipes/flag-url';
import { HereService } from './services/here.service';
import { LocationService } from './services/location.service';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SwalertService } from './services/swalert.service';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { CompanyInfoService } from './services/company-info.service';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { DropdownNavItemComponent } from './components/header/dropdown-nav-item/dropdown-nav-item.component';
import { NavItemComponent } from './components/header/nav-item/nav-item.component';
import { SearchBoxComponent } from './components/header/search-box/search-box.component';
import { SearchBarComponent } from './components/header/search-bar/search-bar.component';
import { MenuLogoComponent } from './components/header/menu-logo/menu-logo.component';
import { LocationContainerComponent } from './components/location-container/location-container.component';
import { LocationSearchComponent } from './components/location-container/location-search/location-search.component';
import { LocationHereSearchComponent } from './components/location-container/location-here-search/location-here-search.component';
import { CountryContainerComponent } from './components/country-container/country-container.component';
import { CountrySearchComponent } from './components/country-container/country-search/country-search.component';
import { NgbTypeaheadModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardService } from './services/clipboard.service';
import { FlipMessageComponent } from './components/flip-message/flip-message.component';

const SHARED_COMPONENTS = [
  FooterComponent,
  HeaderComponent,
  SidebarComponent,
  CountryContainerComponent,
  LocationContainerComponent,
  FlagUrlPipe,
  FlipMessageComponent
];

@NgModule({
  declarations: [...SHARED_COMPONENTS,
    DropdownNavItemComponent,
    NavItemComponent,
    SearchBoxComponent,
    SearchBarComponent,
    MenuLogoComponent,
    LocationSearchComponent,
    CountrySearchComponent,
    LocationHereSearchComponent
  ],
  imports: [
    CommonModule,
    SweetAlert2Module,
    RouterModule,
    HttpClientModule,
    FormsModule,
    NgbTypeaheadModule,
    NgbCollapseModule
  ],
  providers: [
    CompanyInfoService,
    SwalertService,
    LocationService,
    ClipboardService,
    HereService
  ],
  exports: [...SHARED_COMPONENTS]
})
export class SharedModule { }
