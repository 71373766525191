import { UI_KIT_NAV_ITEMS, UI_KIT_DROP_DOWN_ITEMS } from './constants/header.constants';
import { NavMenu } from './model/header.model';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [
    Location, {
        provide: LocationStrategy,
        useClass: PathLocationStrategy
    }
  ]
})
export class HeaderComponent implements OnInit {
  location = 'assets/img/brand/ezclo-logo.jpeg';
  logo: string;
  menu: NavMenu = {
    dropdownItems: UI_KIT_DROP_DOWN_ITEMS,
    navItems: UI_KIT_NAV_ITEMS
  };
  isCollapsed = true;
  constructor(
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.router.events
      .subscribe((event) => {
        // console.log('Routed', this.isCollapsed);
        this.isCollapsed = true;
        if (event instanceof NavigationEnd) {
          this.location = this.router.url;
          if (this.location === '/digital-marketing') {
            this.logo = 'ezclo-logo.png';
          } else {
            this.logo = 'ezclo-logo.jpeg';
          }
        }
      });
  }

  collapse(): void {
    this.isCollapsed = true;
  }

}
