import { NavItem, DropDownNavItem } from '../model/header.model';

export const UI_KIT_DROP_DOWN_ITEMS: DropDownNavItem[] = [
  {
    title: 'Contáctanos',
    dropDownTitle: 'Asesoría',
    url: 'contact-us',
    options: [
      { url: 'faqs', title: 'Preguntas Frecuentes' },
    ]
  }
];

export const UI_KIT_NAV_ITEMS: NavItem[] = [
  { url: 'landing', title: 'Inicio' },
  { url: 'quote', title: 'Cotizador'},
  //{ url: 'contact-us', title: 'Asesoría'},
];
