<div class="mr-auto others-option">
  <ul class="navbar-nav">
      <li class="header-search-box">
          <a href="javascript:void(0)" id="header-search-main" title="Search">
              <i class="icofont-search-2"></i>
          </a>
      </li>
      <li data-toggle="modal" data-target="#myModal2">
          <div class="side-menu">
              <span class="bar-1"></span>
              <span class="bar-2"></span>
              <span class="bar-3"></span>
          </div>
      </li>
  </ul>
</div>
