<!-- Start Navbar Area -->
<header id="header">
  <div class="crake-nav">
      <div class="container">
          <nav class="navbar navbar-expand-md navbar-light">
              <a class="navbar-brand" routerLink="/">
                  <app-menu-logo></app-menu-logo>
              </a>

              <button (click)="isCollapsed = !isCollapsed;" class="navbar-toggler" type="button"   data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
              </button>

              <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent" [ngbCollapse]="isCollapsed">
                  <ul class="navbar-nav nav ml-auto">
                      <app-nav-item (selected)="collapse()" *ngFor="let nav of menu.navItems" [nav]='nav'></app-nav-item>
                      <app-dropdown-nav-item (selected)="isCollapsed = true;" *ngFor="let dropNav of menu.dropdownItems" [nav]='dropNav'></app-dropdown-nav-item>
                  </ul>
              </div>
              <!--app-search-bar></app-search-bar-->
          </nav>
      </div>
  </div>
</header>
<!-- End Navbar Area -->

<app-sidebar></app-sidebar>

<!-- Start Search Box -->
<app-search-box></app-search-box>
<!-- End Search Box -->
