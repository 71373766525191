import { CompanyInfoService } from './shared/services/company-info.service';
import { LoggerService } from './shared/services/logger.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart, NavigationCancel } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { SwUpdate } from '@angular/service-worker';
import { ICompanyData } from './models/company.model';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { ToastController } from '@ionic/angular';
import { environment } from '../environments/environment';
declare let $: any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    Location, {
        provide: LocationStrategy,
        useClass: PathLocationStrategy
    }
]
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'ezclo-landing';
  isLanding = false;
  location: any;
  routerSubscription: any;
  companyData: ICompanyData;
  constructor(
    private router: Router,
    private logger: LoggerService,
    private companyDataProvider: CompanyInfoService,
    private activatedRoute: ActivatedRoute,
    private analytics: AngularFireAnalytics,
    private swUpdate: SwUpdate,
    private toastController: ToastController) {
  }

  ngOnInit(): void {
    this.logger.info('Bootstraping Ezclo', 'APP-COMPONENT');
    this.loaderShow();
    this.companyDataProvider.getCachedStream()
      .subscribe(
        data => {
          data.visitors ++;
          this.companyData = data;
          this.analytics.setAnalyticsCollectionEnabled(!data.disableAnalytics);
          this.analytics.logEvent('LND-Start');
          this.loaderHide();
        },
        error => this.logger.error('Loading company data on init: ', error));

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isLanding = this.activatedRoute.firstChild.snapshot.data.isLanding;
      }
    });
    this.swUpdate.available.subscribe(() => {
      this.presentToastWithOptions();
    });

    this.recallJsFuntions();
  }

  recallJsFuntions(): void {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.loaderShow();
        }
      });
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
      .subscribe(() => {
        this.location = this.router.url;
      });
  }

  loaderShow(): void {
    $('.preloader-area').fadeIn('slow');
  }

  loaderHide(): void {
    $.getScript('../assets/js/custom.js');
    $('.preloader-area').fadeOut('slow');
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  async presentToastWithOptions() {
    const toast = await this.toastController.create({
      header: 'Actualización disponible!',
      message: `La versión ${environment.newVersion} está disponible, nos actualizamos?`,
      position: 'top',
      mode: 'ios',
      buttons: [
        {
          side: 'start',
          icon: 'checkmark-done-outline',
          text: 'Ok',
          handler: () => {
            window.location.reload();
          }
        }
      ]
    });
    toast.present();
    toast.onWillDismiss().catch(() => {
      window.location.reload();
    });
  }
}
