import { DropDownNavItem } from './../model/header.model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dropdown-nav-item',
  templateUrl: './dropdown-nav-item.component.html',
  styleUrls: ['./dropdown-nav-item.component.scss']
})
export class DropdownNavItemComponent implements OnInit {
  @Input() nav: DropDownNavItem;
  @Output() selected = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  onClick(): void {
    this.selected.emit(true);
  }

}
