<!-- Start Footer Area -->
<footer class="footer-area ptb-100 pb-0 bg-image">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="single-footer-widget">
                    <h4><b>{{phrase}}</b></h4>
                    <br>
                    <h6><b>Síguenos</b></h6>
                    <ul class="social-list">
                        <li><a href="https://www.facebook.com/EzcloGuias" target="_blank"><i
                                    class="icofont-facebook"></i></a></li>
                        <li><a href="https://www.instagram.com/ezcloguias?r=nametag" target="_blank"><i
                                    class="icofont-instagram"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget pl-5">
                    <h3>Mensajerías</h3>

                    <ul class="list" *ngIf="parcels.length > 0">
                        <li *ngFor="let item of parcels"><a>{{item.name}}</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget pl-5">
                    <h3>Enlaces</h3>
                    <ul class="list">
                        <li><a routerLink="/contact-us">Contáctanos</a></li>
                        <li><a routerLink="/faqs">Preguntas Frecuentes</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copyright-area">
        <div class="container">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>&copy; 2021 Ezclo. All rights reserved {{version}}</p>
                    <p><small>Developed by </small><a style="color:#678848;" href="https://home.molayadev.com"
                            target="_blank"> <small><b>MOLAYADEV</b></small></a></p>
                </div>
                <div class="col-lg-6 col-md-6">
                  <ul>
                      <li><a href="https://shared.ezclo.net/Go1D" target="_blank" style="color:#678848;"><b>Términos & Condiciones</b></a></li>
                      <li><a href="https://shared.ezclo.net/rniX" target="_blank" style="color:#678848;"><b>Políticas de Privacidad</b></a></li>
                  </ul>
              </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Area -->

<!-- Back Top top -->
<div class="back-to-top">Top</div>
<!-- End Back Top top -->
