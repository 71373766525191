import { Injectable } from '@angular/core';
import Swal, { SweetAlertResult, SweetAlertInput, SweetAlertOptions } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SwalertService {
  private swal: typeof Swal;
  constructor() {
    this.swal = Swal.mixin({
      confirmButtonText: 'Continuar',
      confirmButtonColor: '#a4ce4d',
      // customClass: {
      //  confirmButton: 'btn btn-info',
      //  cancelButton: 'btn btn-dark'
      // },
      backdrop: false,
      buttonsStyling: true
    });
  }

  custom(options: SweetAlertOptions): Promise<SweetAlertResult> {
    return this.swal.fire(options);
  }

  info(title: string, text: string, allowOutsideClick = true, timer?: number): Promise<SweetAlertResult> {
    return this.swal.fire(
      {
        title, text,
        icon: 'info',
        toast: !!timer, timer,
        showConfirmButton: !timer,
        timerProgressBar: !!timer,
        allowOutsideClick
      });
  }

  question(title: string, text: string, allowOutsideClick = true, timer?: number): Promise<SweetAlertResult> {
    return this.swal.fire(
      {
        title, text,
        icon: 'question',
        toast: !!timer, timer,
        showConfirmButton: !timer,
        timerProgressBar: !!timer,
        allowOutsideClick
      });
  }

  error(title: string, text: string, allowOutsideClick = true, timer?: number): Promise<SweetAlertResult> {
    return this.swal.fire(
      {
        title, text,
        icon: 'error',
        toast: !!timer, timer,
        showConfirmButton: !timer,
        timerProgressBar: !!timer,
        allowOutsideClick
      });
  }

  warning(title: string, text: string, allowOutsideClick = true, timer?: number): Promise<SweetAlertResult> {
    return this.swal.fire(
      {
        title, text,
        icon: 'warning',
        toast: !!timer, timer,
        showConfirmButton: !timer,
        timerProgressBar: !!timer,
        allowOutsideClick
      });
  }

  success(title: string, text: string, allowOutsideClick = true, timer?: number): Promise<SweetAlertResult> {
    return this.swal.fire(
      {
        title, text,
        icon: 'success',
        toast: !!timer, timer,
        showConfirmButton: !timer,
        timerProgressBar: !!timer,
        allowOutsideClick
      });
  }


  requestData(options: {
      title: string, inputType?: SweetAlertInput,
      confirmButtonText?: string, url?: string,
      errorMessage?: string, allowOutsideClick: boolean,
      preConfirm?: (data) => any
    }): Promise<SweetAlertResult> {
      const defaultPreConfirm = (data) => {
        return fetch(options.url)
          .then(response => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }
            return response.json();
          })
          .catch(error => {
            this.swal.showValidationMessage(
              `${options.errorMessage}: ${error}`
            );
          });
        };
      return this.swal.fire({
      title: options.title,
      input: options.inputType || 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: options.confirmButtonText || 'Buscar',
      showLoaderOnConfirm: true,
      preConfirm: options.preConfirm || defaultPreConfirm,
      allowOutsideClick: () => !this.swal.isLoading() || options.allowOutsideClick
    });
  }
}
